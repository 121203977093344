<script setup lang="ts">
import { Button, Card } from "@/components/Ui";
import { onEvent } from "@/helpers/events";
import gsap from "gsap";
import { onMounted } from "vue";

const localePath = useLocalePath();
const cardFirstImageEl = ref<HTMLElement | null>(null);
const { t } = useI18n({
  useScope: "local"
});
const onEnter = (el: HTMLElement | null) => {
  gsap.to(el, {
    x: 0,
    y: 0,
    lazy: true,
    duration: 1.5,
    ease: "power3"
  });
};
onMounted(() => {
  onEnter(cardFirstImageEl.value);
});
</script>
<i18n lang="json">
{
  "en": {
    "title_1": "For media",
    "title_2": "buying teams"
  },
  "ru": {
    "title_1": "Для",
    "title_2": "арбитражных",
    "title_3": "команд"
  },
  "ja": {
    "title_1": "メディア",
    "title_2": "バイングチーム用"
  },
  "fr": {
    "title_1": "Pour les équipes",
    "title_2": "d'achat média"
  },
  "es": {
    "title_1": "Para equipos",
    "title_2": "de compra de medios"
  }
}
</i18n>

<template>
  <Card
    :wrapper-class="$style[`root-wrapper`]"
    :description-class="$style[`root-description`]"
    :action-class="$style[`root-action`]"
    :class="`${$style[`root`]}`"
  >
    <template #description>
      <span
        :class="`${$style[`root-subtitle`]} ${
          $style[`root-subtitle-${$i18n.locale}`]
        }`"
      >
        {{ $t("block.specialForMediaTeams.subtitle") }}
      </span>
      <span
        :class="`${$style[`root-title`]} ${
          $style[`root-title-${$i18n.locale}`]
        }`">
        {{ t("title_1") }} <br /> {{ t("title_2") }}
        <template v-if="$i18n.locale === 'ru'">
          <br /> {{ t("title_3") }}
        </template>
      </span>
    </template>
    <template #action>
      <Button
        :title="$t('btn.learnMore')"
        color="white"
        :class="$style[`root-button`]"
        @click="
          onEvent('openPrivateLanding',$router.push(localePath('/private')),{
            'cta type': 'button',
            'cta location': 'Block:Special conditions for media buying teams',
            'cta text': 'Learn more',
          })
        "
      />
    </template>
    <div
      ref="cardFirstImageEl"
      :class="$style[`root-content`]"
    >
      <nuxt-img
        src="/img/block-special-for-teams.png"
        format="webp"
        width="1220"
        height="706"
        sizes="sm:100vw md:50vw lg:680px"
        aria-label="special for teams"
        quality="90"
        loading="lazy"
        placeholder
      />
    </div>
  </Card>
</template>

<style lang="scss" module>
.root {
  @apply rounded-[40px] relative flex flex-col overflow-visible;
  @apply px-[30px] pt-[50px] pb-[30px] lg:px-[50px] lg:pt-[70px] lg:pb-[70px];
  @apply min-h-[500px] xxs:min-h-[600px] lg:min-h-[820px];
  @apply bg-neutral-900;
  &-wrapper {
    @apply h-auto flex flex-col h-full;
  }

  &-description {
    @apply flex flex-col gap-8 h-full;
  }

  &-title {
    @apply leading-[100%] font-black text-white;
    @apply text-[34px] xxs:text-[42px] xs:text-[50px] md:text-[88px] lg:text-[110px] xl:text-[119px];
    &-en,
&-id,
&-da,
&-et,
&-hu,
&-lt,
&-nb,
&-ro,
&-sl,
&-cs,
&-el,
&-fi,
&-lv,
&-nl,
&-sk,
&-sv,
&-he,
    &-tr,
    &-th,
    &-pl,
    &-hi,
    &-ar,
    &-bn,
    &-ko,
    &-vi,
    &-sw{
      @apply text-[34px] xxs:text-[42px] xs:text-[50px] md:text-[88px] lg:text-[110px] xl:text-[119px];
    }

    &-ru {
      @apply text-[28px] xxs:text-[42px] xs:text-[42px] md:text-[78px] lg:text-[106px];
    }

    &-fr {
      @apply text-[32px] xxs:text-[40px] xs:text-[48px] md:text-[84px] lg:text-[110px] xl:text-[119px];
    }

    &-es {
      @apply text-[34px] xxs:text-[44px]  xs:text-[48px] md:text-[88px] lg:text-[110px] xl:text-[119px];
    }

    &-ja {
      @apply text-[28px] xxs:text-[34px] xs:text-[40px] md:text-[82px] lg:text-[110px] xl:text-[119px];
    }
  }

  &-subtitle {
    @apply text-accent-primary leading-none font-medium;
    @apply text-[22px] xxs:text-[28px] xs:text-[32px] lg:text-[37px] xl:text-[47px];
    &-en,
&-id,
&-da,
&-et,
&-hu,
&-lt,
&-nb,
&-ro,
&-sl,
&-cs,
&-el,
&-fi,
&-lv,
&-nl,
&-sk,
&-sv,
&-he,
    &-es,
    &-fr,
    &-tr,
    &-ja {
      @apply text-[22px] xxs:text-[28px] xs:text-[32px] lg:text-[37px] xl:text-[47px];
    }

    &-ru {
      @apply text-[20px] xxs:text-[26px] xs:text-[30px] lg:text-[37px] xl:text-[47px];
    }

    &-es {
      @apply text-[20px] xxs:text-[24px] xs:text-[30px] lg:text-[37px] xl:text-[47px];
    }
  }

  &-action {
    @apply mt-10 xs:mt-[67px] md:mt-auto z-10;
  }

  &-button {
    @apply w-full md:min-w-[240px] md:w-fit;
  }

  &-content {
    @apply absolute right-0 left-0 top-0 bottom-0 h-full w-full flex items-center z-0 translate-x-[500px] translate-y-[500px] z-0;
    & img {
      @apply mt-auto transform transform-gpu translate-x-[20px] translate-y-[-130px] w-[240px] h-auto;

      @screen xxs {
        @apply translate-x-[30px] translate-y-[-140px] w-[300px];
      }

      @screen xs {
        @apply translate-x-[50px] translate-y-[-140px];
      }
      @screen md {
        @apply translate-x-[60%] translate-y-[20%] w-[600px];
      }

      @screen lg {
        @apply translate-x-[68%] translate-y-[0%] w-[700px];
      }
      @screen xl {
        @apply w-[680px];
      }
    }
  }
}
</style>
