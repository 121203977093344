<script setup lang="ts">
import { Button, Card} from "@/components/Ui";
import { onDashboard } from "@/helpers/events";

const { t } = useI18n({
  useScope: "local"
});
</script>
<i18n lang="json">
{
  "en": {
    "title_1": "Crypto",
    "title_2": "& more"
  },
  "ru": {
    "title_1": "Криптой",
    "title_2": "и не",
    "title_3": "только"
  },
  "ja": {
    "title_1": "暗号資産",
    "title_2": "その他"
  },
  "fr": {
    "title_1": "Crypto",
    "title_2": "& plus"
  },
  "es": {
    "title_1": "Crypto",
    "title_2": "y más"
  }
}
</i18n>

<template>
  <Card
    :wrapper-class="$style[`root-wrapper`]"
    :description-class="$style[`root-description`]"
    :class="`${$style[`root`]}`"
  >
    <template #description>
      <span
        :class="`${$style[`root-subtitle`]} ${
          $style[`root-subtitle-${$i18n.locale}`]
        }`"
      >
        {{ $t("block.cryptoAndMore.subtitle") }}
      </span>
      <span
        :class="`${$style[`root-title`]} ${
          $style[`root-title-${$i18n.locale}`]
        }`"
      >
        {{ t("title_1") }}
        <br />
        {{ t("title_2") }}
        <template v-if="$i18n.locale === 'ru'">
          <br class="hidden md:block" />
          {{ t("title_3") }}
        </template>
      </span>
    </template>
    <Button
      :title="$t('btn.learnMore')"
      color="white"
      :class="$style[`root-button`]"
      @click="
        onDashboard('create account started', '/register', {
          'cta type': 'button',
          'cta location': 'Block:Crypto & more.',
          'cta text': 'Learn more',
        })
      "
    />
    <div
      ref="cardFirstImageEl"
      :class="$style[`root-content`]"
    >
      <nuxt-img
        src="/img/crypto-vertical.png"
        class="hidden md:block"
        width="421"
        height="1361"
        format="webp"
        sizes="sm:100vw md:50vw lg:421px"
        aria-label="crypto and more"
        placeholder
        loading="lazy"
      />
      <nuxt-img
        src="/img/crypto-horizontal.png"
        width="891"
        height="276"
        format="webp"
        sizes="sm:100vw md:50vw lg:100px"
        class="md:hidden"
        aria-label="crypto and more"
        placeholder
        loading="lazy"
      />
    </div>
  </Card>
</template>

<style lang="scss" module>
.root {
  @apply rounded-[40px] relative flex flex-col;
  @apply px-[30px] pt-[50px] pb-[30px] lg:px-[50px] lg:pt-[70px] lg:pb-[70px];
  @apply min-h-[500px] xxs:min-h-[600px] lg:min-h-[820px];
  @apply bg-neutral-900;

  &-wrapper {
    @apply h-full w-full;
  }

  &-description {
    @apply flex flex-col gap-8 h-full;
  }

  &-button {
    @apply md:min-w-[240px] mt-auto w-full md:w-fit;
  }

  &-title {
    @apply leading-[100%] xs:leading-[85%] font-black text-white;
    @apply text-[62px] xxs:text-[72px] xs:text-[88px] md:text-[110px] lg:text-[152px] xl:text-[172px];
    &-en,
&-id,
&-da,
&-et,
&-hu,
&-lt,
&-nb,
&-ro,
&-sl,
&-cs,
&-el,
&-fi,
&-lv,
&-nl,
&-sk,
&-sv,
&-he,
    &-tr,
    &-th,
    &-pl,
    &-hi,
    &-ar,
    &-bn,
    &-ko,
    &-vi,
    &-sw{
      @apply text-[62px] xxs:text-[72px] xs:text-[88px] md:text-[110px] lg:text-[152px] xl:text-[172px];
    }

    &-ru {
      @apply text-[38px] xxs:text-[48px] xs:text-[56px] md:text-[100px] lg:text-[138px] xl:text-[172px];
    }

    &-fr {
      @apply text-[62px] xxs:text-[72px] xs:text-[88px] md:text-[110px] lg:text-[152px] xl:text-[172px];
    }

    &-es {
      @apply leading-none text-[62px] xxs:text-[72px] xs:text-[88px] md:text-[110px] lg:text-[152px] xl:text-[172px];
    }

    &-ja {
      @apply leading-[1.1] text-[56px] xxs:text-[70px] xs:text-[80px] md:text-[110px] lg:text-[150px] xl:text-[172px];
    }
  }

  &-subtitle {
    @apply text-accent-primary leading-none font-semibold;
    @apply text-[22px] xxs:text-[28px] xs:text-[32px] lg:text-[37px] xl:text-[47px];
    &-en,
&-id,
&-da,
&-et,
&-hu,
&-lt,
&-nb,
&-ro,
&-sl,
&-cs,
&-el,
&-fi,
&-lv,
&-nl,
&-sk,
&-sv,
&-he,
    &-ru,
    &-tr,
    &-th,
    &-pl,
    &-hi,
    &-ar,
    &-bn,
    &-ko,
    &-vi,
    &-sw{
      @apply text-[22px] xxs:text-[28px] xs:text-[32px] lg:text-[37px] xl:text-[47px];
    }

    &-fr {
      @apply text-[20px] xxs:text-[24px] xs:text-[28px] lg:text-[37px] xl:text-[47px];
    }

    &-es {
      @apply text-[18px] xxs:text-[24px] xs:text-[28px] lg:text-[37px] xl:text-[47px];
    }

    &-ja {
      @apply text-[22px] xxs:text-[28px] xs:text-[32px] lg:text-[37px] xl:text-[47px];
    }
  }

  &-content {
    @apply absolute bottom-0 md:bottom-auto md:left-auto right-0 md:top-0 md:h-full md:mr-10 md:mt-10;
    @apply -translate-y-[160px] md:translate-x-0 md:translate-y-0;
    & img {
      @apply min-w-0 md:w-[197px] lg:w-[276px];
    }
  }
}
</style>
